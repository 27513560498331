import { render, staticRenderFns } from "./DatosExpediente.vue?vue&type=template&id=2a885689&scoped=true&"
import script from "./DatosExpediente.vue?vue&type=script&lang=js&"
export * from "./DatosExpediente.vue?vue&type=script&lang=js&"
import style0 from "./DatosExpediente.vue?vue&type=style&index=0&id=2a885689&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a885689",
  null
  
)

export default component.exports