import moment from 'moment'

const Utils = {
    getItemValue(item, header) {
        const self = this;
        let value = self.getNamespaceValue(item, header.value);
        if (header.valor) {
            value = eval(header.valor);
        }
        if (typeof value == 'undefined' || value == null) {
            return '';
        }
        if (header.tipo && header.tipo == 'date') {
            value = moment(value).format(header.formato || 'DD/MM/YYYY');
        } else if (header.tipo && header.tipo == 'number') {
            value = parseFloat(value).toFixed(header.decimales || 0);
        }
        return value;
    },
    getNamespaceValue(obj, namespace) {
        const self = this;
        if (!Array.isArray(namespace)) {
            namespace = namespace.split('.');
        }
        let res = obj;
        if (namespace.length && obj) {
            let nms = namespace.shift();
            res = obj[nms];
            return self.getNamespaceValue(res, namespace);
        }
        return res;
    },
}

export default Utils;