<template>
  <v-container class="pral-container">
    <v-row>
      <v-col
        cols="12"
        md="4"
        v-for="item in items"
        :key="'item' + item.valor"
      >
        <v-text-field
          color="#3f51b5"
          hide-details
          dense
          :label="item.label"
          :prefix="item.prefix"
          outlined
          :readonly="true"
          :value="getItemValue(item)"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- <v-card
      v-if="acumuladoEsign && acumuladoEsign > 0 && gama.eSign"
      class="mx-auto text-center mt-5"
      :width="chartWidth"
    > -->
      <!-- <v-sheet color="rgba(0, 0, 0, .12)"> -->
      <!-- <apexchart height="350" type="line" :options="chartOptions" :series="series" class="mx-auto"></apexchart> -->
      <!-- </v-sheet> -->
    <!-- </v-card> -->
  </v-container>
    <!-- <v-card-text>Datos de la selección</v-card-text> -->
</template>

<style scoped>
.pral-container {
  border-top: 1px #f3ecec solid;
  margin-top: 3px;
}
</style>
<style>
</style>

<script>

// import Mongo from '../js/ajax/Mongo'
import Utils from '../js/Utils'
// const command = "{aggregate:'lineaHorasGama', pipeline: [ { $match: { 'numeroGama': $$$gamaId } } ], allowDiskUse: true, cursor: {} }";

  export default {
    name: 'DatosSeleccion',

    components: {
    },

    data: () => ({
      Utils,
      acumuladoEsign: 0,
      // chartOptions: {
      //   title: {
      //     text: "Acumulado"
      //   },
      //   chart: {
      //     id: 'Acumulado_e-sign'
      //   },
      //   xaxis: {
      //     categories: []
      //   }
      // },
      // series: [],
      items: [
        {
          label: 'Código',
          valor: 'codigo'
        },
        {
          label: 'Cliente',
          valor: 'cliente.razonSocial'
        },
        {
          label: 'Materia',
          valor: 'formato.materia.codigo'
        },
        {
          label: 'Servicio',
          valor: 'servicio.nombre'
        },
        {
          label: 'Fecha Inspección',
          valor: 'fechaInsp',
          type: 'date'
        },
        {
          label: 'Fecha Próx Inspección',
          valor: 'proxInsp',
          type: 'date'
        },
        {
          label: 'Calificación',
          valor: 'calificacion',
          funcValor: function(item, self) {
            if (!self.expediente.calificacion) return 'Pendiente';
            else if (self.expediente.calificacion == 'DESFAVORABLE') return 'Condicionada';
            else if (self.expediente.calificacion == 'NEGATIVA') return 'Negativa';
            else return 'Favorable';
          }
        },
        {
          label: 'Estado',
          valor: 'estado.estado',
          funcValor: function(item, self) {
            var text = "No definido";
            if (self.expediente && self.expediente.estado && self.expediente.estado.estado) {
              switch (self.expediente.estado.estado) {
                case "GENERADO":
                  text = "Creado";
                  break;
                case "INSPECCIONADO":
                  text = "Inspeccionado";
                  break;
                case "PLANIFICADO":
                  text = "Planificado";
                  break;
                case "AUDITADO":
                  text = "Auditado";
                  break;
                case "PDTE_FACTURAR":
                  text = "Pdte. Facturar";
                  break;
                case "PDTE_CONFORMIDAD":
                  text = "Pdte. Confirmar";
                  break;
                case "PDTE_ENVIO":
                  text = "Pdte. Envío";
                  break;
                case "ENVIADO":
                  text = "Enviado";
                  break;
                case "CANCELADO":
                  text = "Cancelado";
                  break;
                case "PENDIENTE_REVISION_CALIDAD":
                  text = "Pdte. de revisión (calidad)";
                  break;
                case "CORREGIR_ERRORES_AUDITORIA_CALIDAD":
                  text = "Corregir errores (calidad)";
                  break;
                case "PENDIENTE_FIRMA_INSPECTOR":
                  text = "Pdte. de firma";
                  break;
                case "FIRMADO_INSPECTOR":
                  text = "Certificado firmado";
                  break;
              }
            }
            return text;
          }
        },
        {
          label: 'Inspector',
          valor: 'inspectores.0.nombre'
        },
        {
          label: 'Fecha Envío',
          valor: 'fechaEnvio',
          type: 'date',
          funcValor: function(item, self) {
            if (self.expediente.estado && self.expediente.estado.estado && self.expediente.estado.fecha && self.expediente.estado.estado == 'ENVIADO') return self.expediente.estado.fecha;
            else return null;
          }
        }
        // {
        //   label: 'Acumulado',
        //   valor: 'acumuladoEsign',
        //   prefix: "€",
        //   funcValor: function(item, self) {
        //     if (!self.expediente.eSign) {
        //       return '-';
        //     }
        //     return self.acumuladoEsign;
        //   }
        // },
      ],
      
    }),

    props: {
      expediente: Object
    },

    computed: {
      // chartWidth() {
      //   const self = this;
      //   let maxData = 0;
      //   self.series.forEach(s => {
      //     let l = s.data && s.data.length ? s.data.length : 0;
      //     if (l > maxData) {
      //       maxData = l;
      //     }
      //   });
      //   if (maxData < 3) {
      //     maxData = 3;
      //   }
      //   return maxData * 200;
      // } ,
    },

    watch: {
    },

    mounted: function() {
    },

    methods: {
      capitalizeFirstLetter(string) {
          return string.charAt(0).toUpperCase() + string.slice(1);
      },
      getItemValue(item) {
        const self = this;
        let value = Utils.getNamespaceValue(self.expediente, item.valor);
        if (item.funcValor) {
          value = item.funcValor(item, self);
        }
        if (typeof value == 'undefined' || value == null || (typeof value == 'string' && value == '')) {
            return ' ';
        }
        if (item.type && item.type == 'date') {
            value = self.$moment(value).format('DD/MM/YYYY');
        } else if (item.type && item.type == 'boolean') {
          if (value) {
            value = 'Sí';
          } else {
            value = 'No';
          }
        }
        return value;
      }
    }

  }
</script>

