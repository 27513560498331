<template>
  <v-row>
    <v-col cols="12">
      <!-- <v-text-field 
        prepend-icon="mdi-calendar"
        dense
        outlined
        type="date"
        class="form-spam w-33"
        :class="'tipo-date'"
        label="Fecha"
        v-model="selectedDate"
        @change="onChangeDate"
        >
      </v-text-field> -->
    </v-col>
    <v-col v-for="(contador, idx) in contadores"
        :key="'contador'+idx" 
        cols="6" lg="3" sm="4">
      <contador @click="contador.listado ? onClickContador(contador) : null" :contador="contador">
      </contador>
    </v-col>
  </v-row>
</template>

<script>

  import Mongo from '../js/ajax/Mongo'
  import Contador from '../components/Contador.vue'
  // import ListView from './ListView.vue'

  export default {
    name: 'Home',

    components: {
      // ListView
      Contador
    },

    watch: {
      '$route.query.data'() {
        const self = this;
        if (!self.$route.query.data) {
          self.initDate();
          self.refresh();
        }
      }
    },

    data: () => ({
      selectedDate: '',
      contadores: [
        {
          id: 'inspecciones',
          nombre: 'Inspecciones',
          numero: -1,
          incremental: -1,
          comando: "{aggregate:'pedidos', pipeline: [{ $match:{'cliente.codigo': {$in: $$$codigosClientes},'estado.estado': { $ne: 'CANCELADO'},activo: true}},{$unwind: '$lineas'},{$group:{_id: null,n: {$sum: 1}}}], allowDiskUse: true, cursor: {}}",
          classes: {
            // 'light-green--text text--lighten-3': 'contador.incremental <= 20', 
            // 'yellow--text text--lighten-3': 'contador.incremental > 20 && contador.incremental <= 50', 
            // 'orange--text text--lighten-3': 'contador.incremental > 50 && contador.incremental <= 100', 
            // 'red--text text--lighten-3': 'contador.incremental > 100'
          },
          textoPral: 'mdi-newspaper-variant-multiple',
          textoSec: 'INSP',
          tipoSec: 'div',
          field: 'n',
          tiempoIncrementar: 1000,
          dateInTitle: false,
          listado: "{aggregate:'pedidos', pipeline: [{ $match:{'cliente.codigo': {$in: $$$codigosClientes},'estado.estado': { $ne: 'CANCELADO'},activo: true}},{$unwind: '$lineas'},{$sort: {'lineas.fechaInsp': -1}}], allowDiskUse: true, cursor: {}}",
          detalleView: 'expediente',
          detalleParams: {
            expedienteId: 'lineas.idExpediente'
          },
          headers: [
            { text: '', value: 'actions', sortable: false },
            { text: 'Código', value: 'lineas.codigoExpediente', sortable: true },
            { text: 'Cliente', value: 'cliente.razonSocial', sortable: true},
            { text: 'Materia', value: 'lineas.servicio.materia.nombre', sortable: true},
            { text: 'Servicio', value: 'lineas.servicio.nombre', sortable: true},
            { text: 'Fecha Inspección', value: 'lineas.fechaInsp', sortable: true, tipo: 'date', formato: 'DD/MM/YYYY' },
            { text: 'Fecha Próx Inspección', value: 'lineas.fechaRenovacion', sortable: true, tipo: 'date', formato: 'DD/MM/YYYY' },
            { text: 'Calificación', value: 'lineas.calificacion', sortable: true, style: `
              var bgcolor = 'green';
              var color = 'white';
              if (!item.lineas.calificacion) bgcolor = 'gray';
              else if (item.lineas.calificacion == 'DESFAVORABLE') { bgcolor = 'orange'; color = 'black';}
              else if (item.lineas.calificacion == 'NEGATIVA') bgcolor = 'red';
              'text-align: center; border-radius: 30px;color: ' + color + ';font-weight: bold; background-color: ' + bgcolor + ';';
              `, valor: `
              if (!item.lineas.calificacion) 'Pendiente';
              else if (item.lineas.calificacion == 'DESFAVORABLE') 'Condicionada';
              else if (item.lineas.calificacion == 'NEGATIVA') 'Negativa';
              else 'Favorable';
              `
            },
            { text: 'Inspector', value: 'lineas.inspectores.0.nombre', sortable: true},
            { text: 'Estado', value: 'lineas.estadoExpediente', sortable: true, style: `
                var bgcolor = "gray";
                var color = "white";
                if (item && item.lineas.estadoExpediente) {
                  switch (item.lineas.estadoExpediente) {
                    case "GENERADO":
                      bgcolor = "lime";
                      break;
                    case "INSPECCIONADO":
                      bgcolor = "green";
                      break;
                    case "PLANIFICADO":
                      bgcolor = "blue";
                      break;
                    case "AUDITADO":
                      bgcolor = "teal";
                      break;
                    case "PDTE_FACTURAR":
                      bgcolor = "purple";
                      break;
                    case "PDTE_CONFORMIDAD":
                      bgcolor = "purple";
                      break;
                    case "PDTE_ENVIO":
                      bgcolor = "yellow";
                      color = "black";
                      break;
                    case "ENVIADO":
                      bgcolor = "orange";
                      break;
                    case "CANCELADO":
                      bgcolor = "red";
                      break;
                    case "PENDIENTE_REVISION_CALIDAD":
                      bgcolor = "pink";
                      break;
                    case "CORREGIR_ERRORES_AUDITORIA_CALIDAD":
                      bgcolor = "darkblue";
                      break;
                    case "PENDIENTE_FIRMA_INSPECTOR":
                      bgcolor = "purple";
                      break;
                    case "FIRMADO_INSPECTOR":
                      bgcolor = "lightblue";
                      break;
                  }
                }
                'text-align: center; border-radius: 30px;color: ' + color + ';font-weight: bold; background-color: ' + bgcolor + ';';  
              `, 
              valor: `
                var text = "No definido";
                if (item && item.lineas && item.lineas.estadoExpediente) {
                  switch (item.lineas.estadoExpediente) {
                    case "GENERADO":
                      text = "Creado";
                      break;
                    case "INSPECCIONADO":
                      text = "Inspeccionado";
                      break;
                    case "PLANIFICADO":
                      text = "Planificado";
                      break;
                    case "AUDITADO":
                      text = "Auditado";
                      break;
                    case "PDTE_FACTURAR":
                      text = "Pdte. Facturar";
                      break;
                    case "PDTE_CONFORMIDAD":
                      text = "Pdte. Confirmar";
                      break;
                    case "PDTE_ENVIO":
                      text = "Pdte. Envío";
                      break;
                    case "ENVIADO":
                      text = "Enviado";
                      break;
                    case "CANCELADO":
                      text = "Cancelado";
                      break;
                    case "PENDIENTE_REVISION_CALIDAD":
                      text = "Pdte. de revisión (calidad)";
                      break;
                    case "CORREGIR_ERRORES_AUDITORIA_CALIDAD":
                      text = "Corregir errores (calidad)";
                      break;
                    case "PENDIENTE_FIRMA_INSPECTOR":
                      text = "Pdte. de firma";
                      break;
                    case "FIRMADO_INSPECTOR":
                      text = "Certificado firmado";
                      break;
                  }
                }
                text;
              ` 
            },
            { text: 'Fecha Envío', value: 'lineas.camposUsuario.feEnvExp', sortable: true, tipo: 'date', formato: 'DD/MM/YYYY'},
            { text: 'Fecha Tram. EICI', value: 'lineas.fechaTramitacionEICI', sortable: true, tipo: 'date', formato: 'DD/MM/YYYY'},
          ]
        },
      ]
    }),
    methods: {
      onChangeDate() {
        const self = this;
        if(!self.selectedDate) {
          alert('Introduzca una fecha válida');
        } else {
          let data = JSON.stringify({
            selectedDate: self.selectedDate
          });
          self.$router.push({name: 'home', query: {data: window.btoa(data)}});
          self.refresh();
        }
      },
      onClickContador(contador) {
        const self = this;
        let data = JSON.stringify({
          selectedDate: self.selectedDate
        });
        self.$router.push({name: 'list', params: {contadorId: contador.id, selectedDate: self.selectedDate},  query: {data: window.btoa(data)}});
      },
      incrementarContador(contador) {
          contador.incremental = -1;
          contador.interval = setInterval(() => {
            contador.incremental++;
            if (contador.incremental >= contador.numero) {
              clearInterval(contador.interval);
            }
          }, (contador.tiempoIncrementar || 1000)/contador.numero)

          if (contador.separatorField || contador.field2) {
            contador.incremental2 = -1;
            contador.interval2 = setInterval(() => {
              contador.incremental2++;
              if (contador.incremental2 >= contador.numero2) {
                clearInterval(contador.interval2);
              }
            }, (contador.tiempoIncrementar || 1000)/contador.numero2)
          }
      },
      refresh() {
        const self = this;
        let codigosClientes = [];
        if (self.$root.user && self.$root.user.datosEmpleado && self.$root.user.datosEmpleado.clientes && self.$root.user.datosEmpleado.clientes.length) {
          codigosClientes = self.$root.user.datosEmpleado.clientes.map(c => c.codigo);
        }
        self.contadores.forEach(contador => {
          if (contador.comando) {
            let commandStr = contador.comando
              .replaceAll('$$$selectedDate', "'" + self.selectedDate + "'")
              .replaceAll('$$$codigosClientes', "['" + codigosClientes.join("','") + "']");
            Mongo.runCommand(commandStr).then(res => {
              let dto = res.dto;
              if (res.dto.list && res.dto.list.length) {
                dto = res.dto.list[0];
              } 
              contador.numero = dto[contador.field || 'n'] || 0;
              contador.numero2 = dto[contador.field2 || 'n2'] || 0;
              self.incrementarContador(contador);
            });
          }
        });
      },
      initDate() {
        const self = this;
        self.selectedDate = self.$moment().subtract(1, "days").format('YYYY-MM-DD');
        let data = JSON.stringify({
          selectedDate: self.selectedDate
        });
        self.$router.push({name: 'home', query: {data: window.btoa(data)}});
      }
    },
    mounted: function() {
      const self = this;
      let queryData = self.$route.query.data;
      if (queryData) {
        queryData = window.atob(queryData);
      }
      if (queryData) {
        queryData = JSON.parse(queryData) || '';
      }
      if (queryData && queryData.selectedDate) {
        self.selectedDate = queryData.selectedDate;
      } else {
        self.initDate();
      }
      console.log("QUERY DATA", queryData);
      localStorage.setItem('contadores', JSON.stringify(self.contadores));
      self.refresh();
    }
  }
</script>

<style scoped>
.w-100 {
  width: 100%;
}
.w-33 {
  width: 33%;
}
</style>
