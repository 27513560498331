<template>
  <v-card elevation="4" class="card-button" @click="contador.listado ? $emit('click') : null">
    <div class="card-content">
      <div class="numero" :class="getClasses(contador)">{{contador.incremental > -1 ? contador.incremental : '-'}} {{contador.separatorField ? contador.separatorField : ''}}  {{contador.field2 ? (contador.incremental2 > -1 ? contador.incremental2 : '-') : ''}}</div>
      <div v-if="contador.tipoPral && contador.tipoPral == 'div'" class="principal-icon" :class="getClasses(contador)">{{contador.textoPral}}</div>
      <v-icon v-if="contador.textoPral" class="principal-icon" :class="getClasses(contador)">{{contador.textoPral}}</v-icon>
      <div v-if="contador.tipoSec && contador.tipoSec == 'div'" class="secondary-icon" :class="getClasses(contador)">{{contador.textoSec}}</div>
      <v-icon v-else-if="contador.textoSec" class="secondary-icon" :class="getClasses(contador)">{{contador.textoSec}}</v-icon>
    </div>
    <div class="card-text">{{contador.nombre}}</div>
  </v-card>
</template>

<style scoped>
  .card-button .numero {
    font-size: calc(40px + 2.8vw);
    color: white;
    background-color: white;
    text-shadow: -5px -4px 4px #3f51b5, 1px -1px 4px #3f51b5, -5px 2px 4px #3f51b5, 1px 1px 4px #3f51b5;
    transform: rotate(-7deg);
    text-align: left;
    margin-top: calc(-10px + -0.5vw);
    margin-left: calc(2px + 1vw);
  } 
  .card-button .card-content {
    overflow: hidden;
  }
  .card-button .principal-icon {
    position: absolute;
    transform: rotate(-85deg);
    font-size: calc(40px + 2.8vw);
    top: calc(5px + 0.3vw);
    right: calc(5px + 0.5vw);
    color: white;
    text-shadow: -3px -3px 3px #3f51b5, 2px -3px 3px #3f51b5, -1px 1px 3px #3f51b5, 1px 1px 3px #3f51b5;
  }
  .card-button .secondary-icon {
    position: absolute;
    color:white; 
    font-size: calc(20px + 1.5vw);
    top: calc(5px + 0.3vw);
    right: calc(30px + 3vw);
    text-shadow: -2px -2px 2px #3f51b5, 1px -2px 2px #3f51b5, -1px 1px 2px #3f51b5, 1px 1px 2px #3f51b5;
  }
  .card-button .card-text {
    color: white;
    background-color: #3f51b5;
    text-align: center;
    font-size: calc(10px + 1vw);
    padding: calc(5px + 0.2vw);
  }

  @media only screen and (max-width: 600px) {
    .card-button .numero {
      text-shadow: -3px -2px 2px #3f51b5, 1px -1px 2px #3f51b5, -3px 2px 2px #3f51b5, 1px 1px 2px #3f51b5;
    }
    .card-button .principal-icon {
      text-shadow: -2px -2px 2px #3f51b5, 1px -2px 2px #3f51b5, -1px 1px 2px #3f51b5, 1px 1px 2px #3f51b5;
    }
    .card-button .secondary-icon {
      text-shadow: -1px -1px 1px #3f51b5, 1px -1px 1px #3f51b5, -1px 1px 1px #3f51b5, 1px 1px 1px #3f51b5;
    }
  }
</style>

<script>

  export default {
    name: 'Contador',

    components: {
    },

    data: () => ({
    }),

    props: {
      contador: Object
    },

    computed: {
      
    },

    watch: {
    },

    mounted: function() {
    },

    methods: {
      getClasses(contador) {
        if (contador.classes && contador.incremental > -1) {
          if (!contador.classesOriginal) {
            contador.classesOriginal = JSON.parse(JSON.stringify(contador.classes));
          }
          Object.keys(contador.classesOriginal).forEach(k => {
            // console.log(contador.classes[k]);
            contador.classes[k] = eval(contador.classesOriginal[k]);
          });
          return contador.classes;
        } else {
          return {}
        }
      }
    }

  }
</script>

