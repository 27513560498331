<template>
  <div class="listview" v-if="selectedDate">
    <div class="d-flex">
      <v-btn
        text
        color="red"
        @click="volver"
      >
        &lt; Volver
      </v-btn>
      <v-spacer></v-spacer><h3 class="text-center list-title">{{contador.nombre + (contador.dateInTitle ? ' a día ' + $moment(selectedDate,'YYYY-MM-DD').format('DD/MM/YYYY') : '')}}</h3><v-spacer></v-spacer>
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      class="elevation-1"
      dense
      :loading="loading"
      loading-text="Cargando datos..."
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            label="Buscar"
            class="mx-4"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="#3f51b5"
            @click="exportarExcel"
          >
            <v-icon>mdi-file-export-outline</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item="item">
        <tr>
          <td v-for="header in item.headers"
            :key="'item-value-' + item.index + '-' + header.value"
            :class="'style-' + header.tipo"
            :style="header.style ? getEvalValue(header.style, item.item) : null"
            @click="header.click ? execEvalValue(header.click, item.item) : null"
          >
            <template v-if="header.value == 'actions'">
              <v-icon
                small
                v-if="contador.detalleView"
                @click="detalle(item.item)"
              >
                mdi-eye
              </v-icon>
            </template>
            <div v-else>
              {{Utils.getItemValue(item.item, header)}}
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        No hay datos a mostrar
      </template>
    </v-data-table>
  </div>
</template>

<style scoped>
  .list-title {
    color: #3f51b5;
  }
</style>
<style>
</style>

<script>

import Mongo from '../js/ajax/Mongo'
import Utils from '../js/Utils'
import xlsx from "json-as-xlsx"

  export default {
    name: 'ListView',

    components: {
    },

    data: () => ({
      Utils,
      items: [],
      search: '',
      contador: false,
      loading: false,
      selectedDate: ''
    }),

    props: {
      // contador: {
      //   type: Object,
      //   default: function() {
      //     return {};
      //   }
      // },
      contadorId: String
    },

    computed: {
      headers() {
        const self = this;
        let res = [];
        if (self.contador && self.contador.headers && self.contador.headers.length) {
          return self.contador.headers;
        } 
        return res;
      }
    },

    watch: {
    },

    mounted: function() {
      const self = this;
      let contadores = JSON.parse(localStorage.getItem('contadores'));
      self.contador = contadores.filter(c => c.id == self.contadorId)[0];
      if (self.contador) {
        self.loadData();
      }
    },

    methods: {
      execEvalValue(str, item) {
        const self = this;
        console.log(self);
        console.log(item);
        eval(str);
      },
      getEvalValue(str, item) {
        const self = this;
        console.log(self);
        console.log(item);
        return eval(str);
      },
      exportarExcel() {
        const self = this;
        if (self.contador && self.contador.headers) {
          let headers = self.contador.headers.filter(h => h.value != 'actions').map(h => {
            return {
              label: h.text,
              value: (item) => Utils.getItemValue(item, h)
            }
          });
          let settings = {
            fileName: self.contador.nombre + (self.contador.dateInTitle ? ' - ' + self.selectedDate : ''),
            extraLength: 3, // A bigger number means that columns will be wider
            writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
            writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
            RTL: false, // Display the columns from right-to-left (the default value is false)
          };
          let data = 
          [
            {
              sheet: "Listado",
              columns: headers,
              content: self.items
            }
          ];
          xlsx(data, settings);
        }
      },
      volver() {
        const self = this;
        self.$router.back();
      },
      detalle(item) {
        const self = this;
        let params = false;
        if (self.contador.detalleParams) {
          params = JSON.parse(JSON.stringify(self.contador.detalleParams));
          Object.keys(params).forEach(k => {
            let replaceWith = params[k];
            if (replaceWith) {
              let value = Utils.getItemValue(item, {value: replaceWith})
              params[k] = params[k].replaceAll(replaceWith, value);
            }
          });
        }
        self.$router.push({ name: self.contador.detalleView, params: params })
      },
      loadData() {
        const self = this;
        self.items = [];
        self.loading = true;
        let queryData = self.$route.query.data;
        if (queryData) {
          queryData = window.atob(queryData);
        }
        if (queryData) {
          queryData = JSON.parse(queryData) || '';
        }
        if (queryData && queryData.selectedDate) {
          self.selectedDate = queryData.selectedDate;
        }
        let codigosClientes = [];
        if (self.$root.user && self.$root.user.datosEmpleado && self.$root.user.datosEmpleado.clientes && self.$root.user.datosEmpleado.clientes.length) {
          codigosClientes = self.$root.user.datosEmpleado.clientes.map(c => c.codigo);
        }
        let commandStr = self.contador.listado
          .replaceAll('$$$selectedDate', "'" + self.selectedDate + "'")
          .replaceAll('$$$codigosClientes', "['" + codigosClientes.join("','") + "']");
        Mongo.runCommand(commandStr).then(res => {
          self.items = res.dto.list;
          self.loading = false;
        }).catch((e) => {
          console.log(e);
          self.loading = false
        });
      },
    }

  }
</script>

