<template>
  <v-navigation-drawer
    app
    :mini-variant.sync="mini"
  >
    <v-list-item class="px-2">
      <v-list-item-avatar @click.stop="mini = !mini">
        <avatar class="cursor-pointer" :fullname="fullName"></avatar>
        <!-- <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img> -->
      </v-list-item-avatar>

      <v-list-item-title>{{fullName}}<br></v-list-item-title>

      <v-btn
        icon
        @click.stop="mini = !mini"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <!-- <v-list-item
        link
        @click.stop="_ => {$router.push('/')}"
      >
        <v-list-item-icon v-title="'Inicio'">
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Inicio</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <menu-item
        v-for="item in items"
        :key="item.link"
        :item="item"
        :items="items"
      >
      </menu-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

  import Avatar from 'vue-avatar-component';
  import Menu from '../../js/ajax/Menu';
  import MenuItem from './MenuItem.vue';

  export default {
    name: 'Menu',

    data: () => ({
      mini: true,
      items: []
    }),

    components: {
      Avatar,
      MenuItem
    },

    computed: {
      fullName() {
        const self = this;
        return self.$root.user.nombre;
      }
    },

    mounted() {
      Menu.getByCodigo('PORTALCLIENTE').then(res => {
        console.log('Menu.getByCodigo', res);
        this.items = res.dto.items;
      }).catch(e => alert(e));
    }

  }
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.centro-trabajo {
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
}
</style>
