<template>
  <v-card class="h-100">
      <div class="d-flex">
        <v-btn
          text
          color="red"
          @click="volver"
        >
          &lt; Volver
        </v-btn>
        <v-spacer></v-spacer>
        <v-card-title class="text-center">
          <div class="title">
            Expediente {{expediente ? expediente.codigo : ''}}
          </div>
        </v-card-title>
        <v-spacer></v-spacer>
      </div>

    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="#3f51b5"
      grow
    >
      <v-tab key="datos">Datos del expediente</v-tab>
      <!-- <v-tab key="nok">Piezas NOK</v-tab>
      <v-tab key="envios">Envíos</v-tab>
      <v-tab key="phoenix">Vales Phoenix</v-tab> -->
      <v-tab key="documentos">Documentos</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <!-- Datos de la selección -->
      <v-tab-item key="datos">
        <datos-expediente v-if="expediente" :expediente="expediente"></datos-expediente>
      </v-tab-item>
      <v-tab-item key="documentos">
        <documentos v-if="expediente" :expediente="expediente"></documentos>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>

import Mongo from '../js/ajax/Mongo'
import DatosExpediente from '../components/DatosExpediente.vue'
// import Envios from '../components/Envios.vue'
// import Phoenix from '../components/Phoenix.vue'
// import PiezasNok from '../components/PiezasNok.vue'
import Documentos from '../components/Documentos.vue'
const command = "{aggregate:'expedientes', pipeline: [ { $match: { '_id': $$$expedienteId } } ], allowDiskUse: true, cursor: {} }";

  export default {
    name: 'Expediente',

    components: {
      DatosExpediente,
      // Envios,
      // PiezasNok,
      // Phoenix,
      Documentos
    },

    data: () => ({
      expediente: false,
      tab: 0
    }),
    methods: {
      volver() {
        const self = this;
        self.$router.back();
      },
    },
    mounted: function() {
      const self = this;
      let queryData = self.$route.query.data;
      if (queryData) {
        queryData = window.atob(queryData);
      }
      if (queryData) {
        queryData = JSON.parse(queryData) || '';
      }
      if (queryData && queryData.tab) {
        self.tab = queryData.tab;
      }
      let idStr = self.expedienteId.length == 24 ? "ObjectId('" + self.expedienteId + "')" : "'" + self.expedienteId + "'";
      Mongo.runCommand(command.replaceAll('$$$expedienteId', idStr)).then(res => {
        console.log("EXPEDIENTE", res);
        self.expediente = res.dto.list[0];
      });
    },
    props: {
      expedienteId: String
    }
  }
</script>

<style scoped>

.title {
  color: #3f51b5;
}
.h-100 {
  height: 100%;
}

/* .container-data {
  margin: 20px;
} */

@media only screen and (max-width: 600px) {



}
</style>
